import React, { useContext } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import InstructionsTable from "./tools/InstructionsTable";
import InstructionsTableLF2023 from "./tools/InstructionsTableLF2023";
import { UserContext } from "../context/UserContextProvider";
import useDisableBackButton from "./hooks/useDisableBackButton";

const SampleDashboard = () => {

  // Business rules: once you start a sample test, you must complete it
  //  - 'exit sample test' rearranges original access code and pushes user to main Dashboard
  const { setSampleTest, setCurrentStep, setSampleTestDeadline, isLeapFrogTest } = useContext(UserContext)

  useDisableBackButton()  // Disable the browser's back button 
  
  const handleSampleAndCurrentStep = () => {
    setSampleTest()
    setCurrentStep("/patientspdf")
    // create the clientside 'sample deadline'
    setSampleTestDeadline()
  }

    return (
      <Container className="mt-5" style={{ fontWeight: '300' }}>
        <Card className="mb-3">
          <Card.Header className="font-weight-bold">
            Welcome to the Sample Test
          </Card.Header>
          <Card.Body>
          <p>The Sample Test is designed to give your team, including the prescriber, an opportunity to
            practice completing the test process (e.g., time limits and steps, review of Order Checking
            Categories, browser compatibility, lab and ADT links, etc.). The Sample Test:
          </p>
          <ul>
            <li>is not scored</li>
            <li>consists of only two Test Patients and eight Test Orders</li>
            <li>is not meant to predict your hospital's score on the Adult Inpatient Test</li>
            <li>takes approximately 60 minutes to complete</li>
            <li>is subject to the same time limits as the Adult Inpatient Test (3 hours for Steps 1 - 2 and
              3 hours for Steps 3 - 6), but it will not "time out" if those time limits are exceeded</li>
            <li>will start over from the beginning if the browser is closed at any point</li>
            <li>can be taken as many times as necessary without requesting a reset, but it will include
              the same Test Patients and Test Orders each time</li>
          </ul>

          <p>Use the <a href="https://www.leapfroggroup.org/survey-materials/survey-and-cpoe-materials" target="_blank" rel="noreferrer">instructions</a>
            for the Adult Inpatient Test to complete Steps 1 - 6 of the Sample Test, then
            meet with your team to decide if you are ready to schedule the Adult Inpatient Test. Before
            starting the Adult Inpatient Test, make sure browser, printer, or other technical problems
            encountered during the Sample Test are resolved.</p>
          </Card.Body>
        </Card>
        {/* {isLeapFrogTest ? <InstructionsTableLF2023 /> : <InstructionsTable isLeapFrogTest={isLeapFrogTest} />} */}
          <div style={{textAlign: 'right'}}>
            <LinkContainer to="/dashboard">
              <Button variant="warning">Exit Sample Test</Button>
            </LinkContainer>
            {" "}
            <LinkContainer to="/patientspdf">
              <Button onClick={handleSampleAndCurrentStep} variant='info'>Begin Sample Test</Button>
            </LinkContainer>
          </div>
      </Container>
    );
  
}

export default SampleDashboard;